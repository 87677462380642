import React from "react"

import SearchResultsItem from "./SearchResultsItem";
export default () => (
    <section className="container search-results-block">
        <div className="row">
            <div className="col-md-12 ">
                <p className="search-performance">The search found 4 results in 0.02 seconds.</p>
                <h2>Search results</h2>
                <ol className="search-results">
                    <SearchResultsItem title={"test"} link={"#"} body={"Malvern, PA, USA | The  BLH Nobel brand of  Vishay Precision Group (NYSE: VPG) , experts in standard and custom process weighing and force measurement solutions, including high-quality and high-accuracy weighing and control systems, today announced that its G5 Series panel mount measurement amplifiers (G5 PM Series) are now certified “legal-for-trade” via firmware upgrade, as per the very latest standards set forth by EN45501:2015 and the International Organization of Legal Metrology (OIML)."}/>
                    <SearchResultsItem title={"test"} link={"#"} body={"Malvern, PA, USA | The  BLH Nobel brand of  Vishay Precision Group (NYSE: VPG) , experts in standard and custom process weighing and force measurement solutions, including high-quality and high-accuracy weighing and control systems, today announced that its G5 Series panel mount measurement amplifiers (G5 PM Series) are now certified “legal-for-trade” via firmware upgrade, as per the very latest standards set forth by EN45501:2015 and the International Organization of Legal Metrology (OIML)."}/>
                    <SearchResultsItem title={"test"} link={"#"} body={"Malvern, PA, USA | The  BLH Nobel brand of  Vishay Precision Group (NYSE: VPG) , experts in standard and custom process weighing and force measurement solutions, including high-quality and high-accuracy weighing and control systems, today announced that its G5 Series panel mount measurement amplifiers (G5 PM Series) are now certified “legal-for-trade” via firmware upgrade, as per the very latest standards set forth by EN45501:2015 and the International Organization of Legal Metrology (OIML)."}/>


                    <SearchResultsItem title={"test"} link={"#"} body={"Malvern, PA, USA | The  BLH Nobel brand of  Vishay Precision Group (NYSE: VPG) , experts in standard and custom process weighing and force measurement solutions, including high-quality and high-accuracy weighing and control systems, today announced that its G5 Series panel mount measurement amplifiers (G5 PM Series) are now certified “legal-for-trade” via firmware upgrade, as per the very latest standards set forth by EN45501:2015 and the International Organization of Legal Metrology (OIML)."}/>
                </ol>
            </div>
        </div>
    </section>
);
