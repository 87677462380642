const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/gatsby/.cache/dev-404-page.js"))),
  "component---src-pages-404-js": hot(preferDefault(require("/gatsby/src/pages/404.js"))),
  "component---src-pages-search-page-js": hot(preferDefault(require("/gatsby/src/pages/search-page.js"))),
  "component---src-pages-thank-you-js": hot(preferDefault(require("/gatsby/src/pages/thank-you.js"))),
  "component---src-templates-node-js": hot(preferDefault(require("/gatsby/src/templates/node.js")))
}

