import React from "react";



function ContactFormElement({ title, nameclass, type, id, name }) {
    return (
        <div className={nameclass}>
            <label htmlFor={name}>{title}</label>
            <input type={type} id={id} name={name} />
        </div>
    );
}

export default ContactFormElement